import React from "react"
import PropTypes from "prop-types"

const CarouselSterring = ({ swipeNext, swipePrev }) => {
  return (
    <>
      <div className="sterring-container">
        <a className="sterring-btn left-circle" onClick={swipePrev}>
          <svg height="100px" width="100px">
            <g>
              <line x1="40" x2="70" y1="50" y2="50"></line>
              <polyline points=" 45,45 40,50 45,55"></polyline>
              <circle cx="50" cy="50" r="30"></circle>
            </g>
          </svg>
        </a>
        <a className="sterring-btn right-circle" onClick={swipeNext}>
          <svg height="100px" width="100px">
            <g>
              <line x1="30" x2="60" y1="50" y2="50"></line>
              <polyline points=" 55,45 60,50 55,55"></polyline>
              <circle cx="50" cy="50" r="30"></circle>
            </g>
          </svg>
        </a>
      </div>
    </>
  )
}

CarouselSterring.propTypes = {
  swipeNext: PropTypes.func.isRequired,
  swipePrev: PropTypes.func.isRequired,
}

export default CarouselSterring
